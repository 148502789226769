/* You can add global styles to this file, and also import other style files */

:root {
  --color-text: #ddd;
  --color-highlighted: #cfa;
  --color-muted: #aaa;
  --color-link: #88f;
  --color-korpo-050: rgb(2 15 27);
  --color-korpo-100: rgb(4 29 54);
  --color-korpo-200: rgb(29 54 82);
  --color-korpo-300: rgb(48 77 111);
  --color-korpo-400: rgb(58 99 138);
  --color-korpo-500: rgb(77 125 173);
  --color-korpo-600: rgb(92 148 203);
  --color-alert: rgb(105, 52, 52);
  --color-alert-muted: rgb(104, 63, 63);
  --form-paper-background: #eeded0;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

p {
  margin: 0;
  padding: 0;
}

@import "simplebar-react/dist/simplebar.min.css";

.simplebar-scrollbar:before {
  background-color: var(--mui-palette-common-onBackground);
}

.modeSwitch {
  .MuiSwitch-track {
    height: 24px;
    border-radius: 12px;
  }
}

.MuiBreadcrumbs-ol {
  border-radius: 4px;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

fieldset {
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
}

.rdt_TableRow {
  transition: all ease 0.2s;

  &:hover {
    background-color: rgba(
      var(--mui-palette-primary-mainChannel) /
        var(--mui-palette-action-hoverOpacity)
    );
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: var(--mui-palette-TableCell-border);
}
